import Pagination from 'src/components/ui/pagination';
import { Table } from 'src/components/ui/table';
import ActionButtons from 'src/components/common/action-buttons';
import { getIcon } from 'src/utils/get-icon';
import * as categoriesIcon from 'src/components/icons/category';
import { ROUTES } from 'src/utils/routes';
import { useAppDispatch } from '../../utils/reduxHooks';
import { SortOrder } from 'src/ts-types/generated';
import { useState } from 'react';
import TitleWithSort from 'src/components/ui/title-with-sort';
import { Categories, TranslationObject } from 'src/ts-types/custom.types';
import { useTranslation } from 'react-i18next';
import { setActiveCategory } from 'src/store/shop/actions';
import { getActiveCategory } from 'src/utils/categoryUtils';
import { useHistory } from 'react-router-dom';
import { getTranslatedItem } from 'src/common';

export type IProps = {
  categories: Categories;
  onPagination: (key: number) => void;
};
const CategoryList = ({ categories, onPagination }: IProps) => {
  const { t }: any = useTranslation<any>();
  const { data, totalElements, pageSize, pageNumber } = categories;
  const rowExpandable = (record: any) => record.children?.length;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [sortingObj] = useState<{
    sort: SortOrder;
    column: string | null;
  }>({
    sort: SortOrder.Desc,
    column: null,
  });

  const columns = [
    {
      title: (
        <TitleWithSort
          title={t('table-item-title')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'name'}
          isActive={sortingObj.column === 'name'}
        />
      ),
      className: 'cursor-pointer',
      dataIndex: 'categoryNameWithTeanslations',
      key: 'categoryNameWithTeanslations',
      align: 'right',
      width: 100,
      maxWidth: 100,
      render: (categoryNameWithTeanslations: TranslationObject[]) => {
        if (!categoryNameWithTeanslations) return null;
        return <>{getTranslatedItem(categoryNameWithTeanslations)}</>;
      },
    },
    {
      title: t('table-item-totalProducts'),
      dataIndex: 'totalProducts',
      key: 'totalProducts',
      align: 'center',
      width: 80,
      maxWidth: 80,
      render: (totalProducts: number) => {
        return <span className='flex items-center justify-center'>{totalProducts}</span>;
      },
    },
    {
      title: t('table-item-icon'),
      dataIndex: 'categoryTagIcons',
      key: 'categoryTagIcons',
      align: 'center',
      width: 80,
      maxWidth: 80,
      render: (icon: string) => {
        if (!icon) return null;
        return (
          <span className='flex items-center justify-center'>
            {getIcon({
              iconList: categoriesIcon,
              iconName: icon,
              className: 'w-5 h-5 max-h-full max-w-full',
            })}
          </span>
        );
      },
    },
    {
      title: t('input-label-category-id'),
      dataIndex: 'categoryId',
      key: 'categoryId',
      align: 'center',
      width: 120,
      maxWidth: 120,
      render: (categoryId: string) => {
        return <>{categoryId}</>;
      },
    },
    // {
    //   title: t('input-label-status'),
    //   dataIndex: 'active',
    //   key: 'active',
    //   align: 'center',
    //   width: 120,
    //   maxWidth: 120,
    //   render: (_: any, record: Category) => {
    //     return (
    //       <SwitchInput
    //         label={t('table-item-status')}
    //         name='active'
    //         className='pb-2 pl-6'
    //         onChange={(val) => handleUpdate(record, val)}
    //         defaultValue={record.active}
    //         value={record.active}
    //       />
    //     );
    //   },
    // },
    {
      title: t('table-item-actions'),
      dataIndex: 'categoryId',
      key: 'categoryId',
      align: 'center',
      width: 120,
      maxWidth: 120,
      render: (categoryId: string) => {
        return (
          <ActionButtons
            id={categoryId}
            editAction={() => onRowClick(getActiveCategory(data, categoryId))}
            editUrl={ROUTES.EDIT_CATEGORY}
            deleteModalView='DELETE_CATEGORY'
          />
        );
      },
    },
  ];

  const onRowClick = (record: any) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dispatch(setActiveCategory(getActiveCategory(data, record.categoryId)));
    history.push(ROUTES.EDIT_CATEGORY);
  };

  // const handleUpdate = (record: Category, active: boolean) => {
  //   const input: CreateCategoryInput = {
  //     categoryTagIcons: record?.categoryTagIcons,
  //     categoryImage: record.categoryImage,
  //     parentCategoryId: record.categoryId,
  //     active: active,
  //     orderNumber: record.orderNumber,
  //     categoryNameWithTeanslations: record.categoryNameWithTeanslations,
  //   };
  //   input.categoryId = record.categoryId;
  //   dispatch(updateCategoryAction(input)).then((res: any) => {
  //     toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
  //     history.push(ROUTES.CATEGORIES);
  //   });
  // };

  return (
    <>
      <div className='rounded overflow-hidden shadow mb-6 step4'>
        <Table
          // @ts-ignore
          columns={columns}
          emptyText={t('empty-table-data')}
          data={data ?? []}
          rowKey='categoryId'
          scroll={{ x: 1000 }}
          className='cursor_table'
          onRow={(record: any, index: any) => ({
            // onClick: onRowClick.bind(null, record),
            // style: {
            //   cursor: 'pointer',
            // },
          })}
          expandable={{
            expandedRowRender: () => '',
            rowExpandable: rowExpandable,
          }}
        />
      </div>
      {!!totalElements && (
        <div className='flex justify-end items-center smx:hidden'>
          <Pagination
            total={totalElements}
            current={pageNumber}
            pageSize={pageSize}
            onChange={onPagination}
          />
        </div>
      )}
    </>
  );
};

export default CategoryList;
