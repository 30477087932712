import { ShopAnalyticsResponse } from 'src/ts-types/custom.types';
import StickerCard from '../widgets/sticker-card';
import { DollarIcon } from '../icons/shops/dollar';
import { CartIconBig } from '../icons/cart-icon-bag';
import { CoinIcon } from '../icons/coin-icon';
import { formatPrice } from 'src/utils/use-priceNew';
import { getLocale } from 'src/common';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ProductIcon } from '../icons/product-icon';

type AnalyticsWidgetProps = {
  analytics: ShopAnalyticsResponse;
};

const AnalyticsWidget: React.FC<AnalyticsWidgetProps> = ({ analytics }: AnalyticsWidgetProps) => {
  const { data } = analytics;

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 3,
    responsive: [
      {
        breakpoint: 768,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 4,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getTotalProducts = () => {
    const activeProducts = data?.totalActiveProducts ?? 0;
    const inActiveProducts = data?.totalInactiveProduct ?? 0;
    return activeProducts + inActiveProducts;
  };

  return (
    <div className='w-full mb-6'>
      <Slider {...settings}>
        <div className='pl-2'>
          <StickerCard
            titleTransKey='today_revenue'
            stepName='step1'
            subtitleTransKey='sticker-card-subtitle-rev'
            icon={<DollarIcon className='smx:w-3 smx:h-3 w-7 h-7' color='#047857' />}
            iconBgStyle={{ backgroundColor: '#A7F3D0' }}
            count={formatPrice({
              price: data?.todaysRevenue ?? 0,
              currencyCode: 'ILS',
              locale: getLocale(),
            })}
          />
        </div>
        <div className='pl-2'>
          <StickerCard
            titleTransKey='total_pending_orders'
            stepName='step1'
            subtitleTransKey='sticker-card-subtitle-rev'
            icon={<CartIconBig />}
            iconBgStyle={{ backgroundColor: '#A7F3D0' }}
            count={data?.totalPendingOrders}
          />
        </div>
        {/* Slide 1 */}
        <div className='pl-2'>
          <StickerCard
            titleTransKey='total_revenue'
            stepName='step1'
            subtitleTransKey='sticker-card-subtitle-rev'
            icon={<DollarIcon className='smx:w-3 smx:h-3 w-7 h-7' color='#047857' />}
            iconBgStyle={{ backgroundColor: '#A7F3D0' }}
            count={formatPrice({
              price: data?.totalRevenue ?? 0,
              currencyCode: 'ILS',
              locale: getLocale(),
            })}
          />
        </div>
        {/* Slide 2 */}
        <div className='pl-2'>
          <StickerCard
            stepName='step2'
            titleTransKey='total_orders'
            subtitleTransKey='sticker-card-subtitle-order'
            icon={<CartIconBig />}
            count={data?.totalOrders}
          />
        </div>
        {/* Slide 3 */}
        <div className='pl-2'>
          <StickerCard
            stepName='step2'
            titleTransKey='products'
            icon={<ProductIcon />}
            subtitleTransKey='sticker-card-subtitle-order'
            count={getTotalProducts()}
          />
        </div>
      </Slider>
    </div>
  );
};

export default AnalyticsWidget;
