/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'src/components/ui/table';
import { format } from 'date-fns';
import {
  CustomerDetails,
  Order,
  Orders,
  OrderStatus,
  PaymentDetails,
} from 'src/ts-types/custom.types';
import { SortOrder } from 'src/ts-types/generated';
import Pagination from '../ui/pagination';
import ActionButtons from '../common/action-buttons';
import { ROUTES } from 'src/utils/routes';
import { useAppDispatch } from 'src/utils/reduxHooks';
import TitleWithSort from '../ui/title-with-sort';
import { useHistory } from 'react-router-dom';
import Button from '../ui/button';
import { ORDER_STATUS } from 'src/ts-types';
import { getUserOrders } from 'src/store/shop/shopsAPIActions';
import { renderOrderStatus } from 'src/utils/shopUtils';
import Card from '../common/card';
import { filterActions } from './filterFunction';

export type IProps = {
  orders?: Orders;
  onPagination: (current: number) => void;
  onSort: (current: any) => void;
  onOrder: (current: string) => void;
};

type SortingObjType = {
  sort: SortOrder;
  column: string | null;
};

const OrderList = ({ orders, onPagination, onSort, onOrder }: IProps) => {
  const { data, totalElements, pageSize, pageNumber, isFetching } = orders! ?? {};

  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [sortingObj, setSortingObj] = useState<SortingObjType>({
    sort: SortOrder.Desc,
    column: null,
  });

  const sortOrdersByPrice = useCallback(
    (orders: Order[]) => {
      let sortedProducts;
      if (sortingObj.sort === SortOrder.Asc) {
        sortedProducts = [...orders].sort(
          (a, b) =>
            // @ts-ignore
            a.paymentDetails.finalTotalPriceWithoutTax - b.paymentDetails.finalTotalPriceWithoutTax,
        );
      } else {
        sortedProducts = [...orders].sort(
          (a, b) =>
            // @ts-ignore
            b.paymentDetails.finalTotalPriceWithoutTax - a.paymentDetails.finalTotalPriceWithoutTax,
        );
      }
      return sortedProducts;
    },
    [sortingObj.sort],
  );

  const sortOrdersByName = useCallback(
    (orders: Order[]) => {
      let sortedOrders;
      if (sortingObj.sort === SortOrder.Asc) {
        sortedOrders = [...orders].sort((a, b) =>
          (a?.customerDetails?.customerName ?? '') > (b?.customerDetails?.customerName ?? '')
            ? -1
            : 1,
        );
      } else {
        sortedOrders = [...orders].sort((a, b) =>
          (a?.customerDetails?.customerName ?? '') > (b?.customerDetails?.customerName ?? '')
            ? 1
            : -1,
        );
      }
      return sortedOrders;
    },
    [sortingObj.sort],
  );

  useEffect(() => {
    let sortedOrders;
    if (orders?.data != null && sortingObj.column != null) {
      switch (sortingObj.column) {
        case 'price':
          sortedOrders = sortOrdersByPrice(orders!.data);
          break;
        default:
          sortedOrders = sortOrdersByName(orders!.data);
          break;
      }
      orders!.data = sortedOrders;
    }
  }, [sortingObj, orders, sortOrdersByPrice, sortOrdersByName]);

  const onPageClicked = (value: number) => {
    onPagination(value);
  };

  const onHeaderClick = (column: string | null) => ({
    onClick: () => {
      onSort((currentSortDirection: SortOrder) =>
        currentSortDirection === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
      );
      onOrder(column!);

      setSortingObj({
        sort: sortingObj.sort === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc,
        column: column,
      });
    },
  });

  const onFilterClick = (query: string) => {
    dispatch(getUserOrders(pageNumber ?? 1, query));
  };

  const onRowClick = (record: Order) => {
    history.push(`${ROUTES.ORDERS}${record.orderId}`);
  };

  const columns = [
    {
      title: (
        <TitleWithSort
          title={t('input-label-customer-name')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'name'}
          isActive={sortingObj.column === 'name'}
        />
      ),
      dataIndex: 'customerDetails',
      className: 'cursor-pointer',
      key: 'customerDetails',
      align: 'center',
      width: 74,
      render: (customerDetails: CustomerDetails) => {
        if (!customerDetails) return null;
        return <>{customerDetails.customerName}</>;
      },
      onHeaderCell: () => onHeaderClick('name'),
    },
    {
      title: t('creation-date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 74,
      render: (createdAt: string) => {
        return <>{format(new Date(createdAt ?? ''), 'uuuu-MM-dd')}</>;
      },
    },
    {
      title: (
        <TitleWithSort
          title={t('table-item-total')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'price'}
          isActive={sortingObj.column === 'price'}
        />
      ),
      dataIndex: 'paymentDetails',
      className: 'cursor-pointer',
      key: 'paymentDetails',
      align: 'center',
      width: 74,
      render: (paymentDetails: PaymentDetails) => {
        if (!paymentDetails) return null;
        return <>{(Math.round(paymentDetails.finalTotalPriceWithTax * 100) / 100).toFixed(2)} ₪</>;
      },
      onHeaderCell: () => onHeaderClick('price'),
    },
    {
      title: (
        <TitleWithSort
          title={t('table-item-status')}
          ascending={sortingObj.sort === SortOrder.Asc && sortingObj.column === 'orderStatus'}
          isActive={sortingObj.column === 'orderStatus'}
        />
      ),
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      align: 'center',
      width: 74,
      render: (orderStatus?: OrderStatus) => {
        return <>{renderOrderStatus(orderStatus)}</>;
      },
      onHeaderCell: () => onHeaderClick('orderStatus'),
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'id',
      key: 'actions',
      align: 'center',
      width: 100,
      render: (id: string, record: Order) => {
        return (
          <ActionButtons id={record?.orderId || ''} editUrl={`${ROUTES.ORDERS}${record.orderId}`} />
        );
      },
    },
  ];

  const renderCardView = () => (
    <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:hidden'>
      {data?.map((order: Order) => (
        <Card
          key={order.orderId}
          className='border rounded-md p-4 shadow hover:shadow-lg cursor-pointer'
          onClick={() => onRowClick(order)}
        >
          <div className='flex justify-between items-center'>
            <h3 className='text-lg font-medium'>{order.customerDetails?.customerName}</h3>
            <span className='text-sm text-gray-500'>
              {format(new Date(order.createdAt ?? ''), 'uuuu-MM-dd')}
            </span>
          </div>
          <div className='mt-2'>
            <p className='text-gray-700 font-semibold'>
              {t('table-item-total')}: {order.paymentDetails?.finalTotalPriceWithTax?.toFixed(2)} ₪
            </p>
            <p className='text-gray-700 pt-2'>
              {t('table-item-status')}: {renderOrderStatus(order.orderStatus)}
            </p>
          </div>
        </Card>
      ))}
    </div>
  );

  return (
    <>
      <div className='smx:hidden flex mb-2 items-center justify-center'>
        {filterActions.map((item: any, index: number) => (
          <Button
            key={index}
            size='small'
            variant='outline'
            className='ml-2'
            onClick={() => dispatch(getUserOrders(pageNumber ?? 1, item.query))}
          >
            {t(item.name)}
          </Button>
        ))}
      </div>

      {/* Table View for larger screens */}
      <div className='hidden lg:block'>
        <Table
          /* @ts-ignore */
          columns={columns}
          emptyText={t('empty-table-data')}
          data={data}
          rowKey='orderId'
          scroll={{ x: 900 }}
          // @ts-ignore
          onRow={(record: Order, index: Order) => ({
            onClick: onRowClick.bind(null, record),
            style: {
              cursor: 'pointer',
            },
          })}
          className='cursor_table'
        />
      </div>

      {/* Card View for smaller screens */}
      <div className='block lg:hidden'>{renderCardView()}</div>

      {!!totalElements && (
        <div className='smx:hidden flex justify-center items-center mt-4 '>
          <Pagination
            total={totalElements}
            current={pageNumber}
            pageSize={pageSize}
            onChange={onPagination}
            showLessItems
          />
        </div>
      )}
    </>
  );
};

export default OrderList;
