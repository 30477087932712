import { ORDER_STATUS } from 'src/ts-types';

export const filterActions = [
  {
    name: 'clear',
    query: '',
  },
  {
    name: 'pending',
    query: `orderStatus=${ORDER_STATUS.IN_PREPARATION.toLocaleUpperCase()}`,
  },
  {
    name: 'completed',
    query: `orderStatus=${ORDER_STATUS.COMPLETED.toLocaleUpperCase()}`,
  },
  {
    name: 'canceled',
    query: `orderStatus=${ORDER_STATUS.CANCELED.toLocaleUpperCase()}`,
  },
];
