import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBoxOpen, faClipboardList, faCog } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';

export const MobileNavbar = () => {
  const history = useHistory();
  const { t }: any = useTranslation<any>();

  return (
    <nav className='fixed bottom-0 left-0 right-0 bg-white border-t shadow-md md:hidden'>
      <div className='flex justify-around items-center p-2'>
        <button className='flex flex-col items-center' onClick={() => history.push(ROUTES.SHOPS)}>
          <FontAwesomeIcon icon={faHome as IconProp} className='w-6 h-6' color='#38B999' />
          <span className='text-xs mt-1'>{t('home')}</span>
        </button>
        <button
          className='flex flex-col items-center'
          onClick={() => history.push(ROUTES.PRODUCTS)}
        >
          <FontAwesomeIcon icon={faBoxOpen as IconProp} className='w-6 h-6' color='#38B999' />
          <span className='text-xs mt-1'>{t('products')}</span>
        </button>
        <button className='flex flex-col items-center' onClick={() => history.push(ROUTES.ORDERS)}>
          <FontAwesomeIcon icon={faClipboardList as IconProp} className='w-6 h-6' color='#38B999' />
          <span className='text-xs mt-1'>{t('orders')}</span>
        </button>
      </div>
    </nav>
  );
};
