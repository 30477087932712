import Pagination from 'src/components/ui/pagination';
import { Table } from 'src/components/ui/table';
import ActionButtons from 'src/components/common/action-buttons';
import { ROUTES } from 'src/utils/routes';
import { useAppDispatch } from '../../utils/reduxHooks';
import { SortOrder } from 'src/ts-types/generated';
import { useState } from 'react';
import TitleWithSort from 'src/components/ui/title-with-sort';
import { Brand, Brands, TranslationObject } from 'src/ts-types/custom.types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { setShopActiveBrand } from 'src/store/shop/actionTypes';
import { getActiveBrand } from 'src/utils/productUtils';
import { getTranslatedItem } from 'src/common';

export type IProps = {
  brands: Brands;
  onPagination: (key: number) => void;
};
const BrandList = ({ brands, onPagination }: IProps) => {
  const { t }: any = useTranslation<any>();
  const { data, totalElements, pageSize, pageNumber } = brands! ?? {};
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [sortingObj] = useState<{
    sort: SortOrder;
    column: string | null;
  }>({
    sort: SortOrder.Desc,
    column: null,
  });

  const columns = [
    {
      title: (
        <TitleWithSort
          title={t('table-item-title')}
          ascending={
            sortingObj.sort === SortOrder.Asc && sortingObj.column === 'brandNameWithTeanslations'
          }
          isActive={sortingObj.column === 'brandNameWithTeanslations'}
        />
      ),
      className: 'cursor-pointer',
      dataIndex: 'brandNameWithTeanslations',
      key: 'brandNameWithTeanslations',
      align: 'right',
      width: 100,
      maxWidth: 100,
      render: (brandNameWithTeanslations: TranslationObject[]) => {
        if (!brandNameWithTeanslations) return null;
        return <>{getTranslatedItem(brandNameWithTeanslations)}</>;
      },
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'brandId',
      key: 'brandId',
      align: 'center',
      width: 120,
      maxWidth: 120,
      render: (brandId: string) => {
        return (
          <ActionButtons
            id={brandId}
            editAction={() => onRowClick(getActiveBrand(data, brandId)!)}
            editUrl={ROUTES.EDIT_BRAND}
            deleteModalView='DELETE_BRAND'
          />
        );
      },
    },
  ];

  const onRowClick = (record: Brand) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dispatch(setShopActiveBrand(record));
    history.push(ROUTES.EDIT_BRAND);
  };

  return (
    <>
      <div className='rounded overflow-hidden shadow mb-6 step4'>
        <Table
          /* @ts-ignore */ x
          columns={columns}
          className='cursor_table'
          onRow={(record: Brand) => ({
            onClick: onRowClick.bind(null, record),
            style: {
              cursor: 'pointer',
            },
          })}
          emptyText={t('empty-table-data')}
          data={data}
          rowKey='id'
          scroll={{ x: 900 }}
        />
      </div>
      {!!totalElements && (
        <div className='flex justify-end items-center smx:hidden'>
          <Pagination
            total={totalElements}
            current={pageNumber}
            pageSize={pageSize}
            onChange={onPagination}
          />
        </div>
      )}
    </>
  );
};

export default BrandList;
