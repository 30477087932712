/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/common/card';
import Search from 'src/components/common/search';
import { MoreIcon } from 'src/components/icons/more-icon';
import cn from 'classnames';
// @ts-ignore
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import OrderList from 'src/components/order/order-list';
import { SortOrder } from 'src/ts-types/generated';
import { withPermission } from 'src/HOC/withPermission';
import { RootState } from 'src/store';
import { getAllOrders, getUserOrders } from 'src/store/shop/shopsAPIActions';
import Loader from 'src/components/ui/loader/loader';
import Joyride from 'react-joyride';
import { filterActions } from '../../components/order/filterFunction';

const OrdersPage = () => {
  const orders = useAppSelector((state: RootState) => state.shop.shopDetails?.orders);
  const { t }: any = useTranslation();
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrder] = useState('created_at');
  const [sortedBy, setColumn] = useState<SortOrder>(SortOrder.Desc);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pageNumber } = orders! ?? {};

  useEffect(() => {
    if (searchTerm !== '') {
      dispatch(getUserOrders(page, `userId=${searchTerm}`));
    } else {
      dispatch(getAllOrders(page));
    }
  }, [searchTerm, page]);

  // if (fetchingShop) return <Loader text={t("text-loading")} />;
  // if (error) return <ErrorMessage message={error.message} />;

  const toggleVisible = () => {
    setVisible((v) => !v);
  };

  function handleSearch({ searchText }: { searchText: string }) {
    setSearchTerm(searchText);
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  function handlePagination(current: any) {
    setPage(current);
  }
  if (orders?.isFetching) return <Loader text={t('text-loading')} />;
  const steps = [
    {
      target: '.step1orders',
      content: t('step1orders'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step2orders',
      content: t('step2orders'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step3orders',
      content: t('step3orders'),
      locale: {
        last: 'סיום',
        next: 'סיום', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
  ];
  [
    {
      target: '.tourSelector',
      content: 'This is the coolest feature on this page',
      title: 'You can have a title here!',
      styles: {
        // this styles override the styles in the props
        options: {
          textColor: 'tomato',
        },
      },
      locale: {
        next: <span>GO GO GO</span>,
        back: <span>BACK BACK</span>,
      },
      placement: 'top',
    },
  ];
  return (
    <>
      {process.env.TOUR_IS_ACTIVE === 'true' && (
        <Joyride
          steps={steps}
          // callback={handleCallback}
          scrollToFirstStep
          showSkipButton
          styles={{
            options: {
              zIndex: 2000000,
            },
          }}
          continuous
          hideCloseButton
          showProgress
        />
      )}
      <Card className='flex flex-col mb-4 smx:mb-2'>
        <div className='w-full flex md:flex-row smx:flex-row items-center'>
          <div className='md:w-1/4 mb-4 md:mb-0 smx:hidden'>
            <h1 className='text-lg font-semibold text-heading'>{t('input-label-orders')}</h1>
          </div>

          <div className='w-full md:w-3/4 flex flex-col md:flex-row items-center'>
            <div className='w-full flex items-center step1orders relative'>
              <Search onSearch={handleSearch} placeholder={t('search-by-user-name')} />
              <button
                className='md:hidden md:flex w-8 h-8 items-center justify-center flex-shrink-0 ms-5 transition duration-300'
                onClick={toggleMenu}
              >
                <MoreIcon className='w-8 text-body pr-2' />
              </button>

              {/* Dropdown list */}
              {isMenuOpen && (
                <div className='absolute top-12 left-0 bg-white border border-gray-200 rounded shadow-md z-10'>
                  <ul>
                    {filterActions.map((item: any, index: number) => (
                      <li
                        key={index}
                        onClick={() => {
                          setIsMenuOpen(false),
                            dispatch(getUserOrders(pageNumber ?? 1, item.query));
                        }}
                        className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                      >
                        {t(item.name)}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={cn('w-full flex transition', {
            'h-auto visible': visible,
            'h-0 invisible': !visible,
          })}
        ></div>
      </Card>
      <OrderList
        orders={orders}
        onPagination={handlePagination}
        onOrder={setOrder}
        onSort={setColumn}
      />
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default withPermission(OrdersPage);
