import { useEffect } from 'react';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from 'src/firebase';
import { addDeviceToken } from 'src/store/session/actions';
import { useAppDispatch } from 'src/utils/reduxHooks';

export const useNotificationSetup = (userId: string | null) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userId) return; // Do nothing if userId is null or empty

    const setupNotifications = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID,
          });
          if (token) {
            console.log('FCM Token:', token);
            await dispatch(addDeviceToken({ userId, deviceToken: token }));
          } else {
            console.warn('No FCM token retrieved.');
          }
        } else {
          console.warn('Notification permission not granted.');
        }
      } catch (error) {
        console.error('Error setting up notifications:', error);
      }
    };

    setupNotifications();

    const unsubscribe = onMessage(messaging, async (payload) => {
      console.log('Message received in foreground:', payload);
    });

    return () => unsubscribe();
  }, [userId, dispatch]);
};
