import { useEffect } from 'react';
import MySitesView from 'src/components/shop/user/mySitesView';
import { useAppDispatch } from 'src/utils/reduxHooks';
import { isSuperAdmin } from 'src/utils/authUtils';
import AllShopPage from '../createShop';
import { getAllMyShops } from 'src/store/shop/shopsAPIActions';

/**
 *
 * @returns entry page.
 */
export default function HomePage() {
  const dispatch = useAppDispatch();

  const superUser = isSuperAdmin();

  useEffect(() => {
    dispatch(getAllMyShops());
    setTimeout(() => {
      dispatch(getAllMyShops());
    }, 100);
  }, []);

  if (superUser) {
    return <AllShopPage />;
  }
  return <MySitesView />;
}
