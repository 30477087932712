import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import LoginPage from 'src/pages/auth/Login';
import AdminContactUsPage from 'src/pages/adminContactUsPage';
import RegisterPage from 'src/pages/auth/Register';
import { UIProvider } from 'src/contexts/ui.context';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { store, persistor } from './store/index';

import './App.css';
import { ROUTES } from './utils/routes';
import ManagedModal from './components/ui/modal/managed-modal';
import { TermsAndConditions } from './pages/termsAndPolicy/TermsAndConditions';
import { PrivacyAndPolicy } from './pages/termsAndPolicy/PrivacyAndPolicy';
import AnonymousCreateShop from './pages/anonymousCreateShop/index';
import BusinessDetailsPage from './pages/anonymousCreateShop/businessDetailsPage';

import ShopLayout from './components/layouts/shop';
import MetaPixel from './utils/metaPixel';
import { useEffect } from 'react';

if (process.env.NODE_ENV === 'development') {
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (typeof args[0] === 'string' && args[0].includes('ResizeObserver loop limit exceeded')) {
      return;
    }
  };
}
const App = () => {
  console.log('process.env.REACT_APP_MAINTENANCE_MODE ', process.env.REACT_APP_MAINTENANCE_MODE);
  const isMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }
  }, []);

  if (isMaintenance) {
    return (
      <div className='flex items-center justify-center h-screen bg-gray-900 text-white'>
        <div className='text-center px-4'>
          <h1 className='text-4xl font-bold mb-4 text-accent'>נחזור בקרוב!</h1>
          <p className='text-lg mb-8'>האתר שלנו עובר תחזוקה כרגע. תודה על הסבלנות.</p>
          <div className='flex justify-center'>
            <div className='w-12 h-12 border-t-4 border-accent border-solid rounded-full animate-spin'></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MetaPixel />
        <UIProvider>
          <BrowserRouter>
            <Switch>
              <Route path={`${ROUTES.LOGIN}`} component={LoginPage} />
              <Route exact path={ROUTES.REGISTER} component={RegisterPage} />
              <Route exact path={ROUTES.ADMIN_CONTACT_US} component={AdminContactUsPage} />
              <Route exact path={ROUTES.ANONY_CREATE_SHOP} component={AnonymousCreateShop} />
              <Route
                exact
                path={ROUTES.ANONY_CREATE_SHOP_LAST_STEP}
                component={BusinessDetailsPage}
              />
              <Route exact path={ROUTES.TERMS_AND_CONDITION} component={TermsAndConditions} />
              <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyAndPolicy} />
              <Route
                path={[ROUTES.SHOPS, ROUTES.ENTRY]}
                render={(props) => <ShopLayout {...props} />}
              />
            </Switch>
            <ManagedModal />
          </BrowserRouter>
        </UIProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
