import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Configuration for the first Firebase project
const firebaseConfig1 = {
  apiKey: 'AIzaSyDnHEfPznNxpEFBKIanq3AwjMV9zZIta88',
  authDomain: 'theshop-334912.firebaseapp.com',
  projectId: 'theshop-334912',
  storageBucket: 'theshop-334912.appspot.com',
  messagingSenderId: '1076952507379',
  appId: '1:1076952507379:web:0aefecf3e2cbd1e973a886',
  measurementId: 'G-FG8TZVW6Z6',
};

// Configuration for the second Firebase project
const firebaseConfig2 = {
  apiKey: 'AIzaSyBGHrp4OYO5nYsSx2O8UPoXHcKt3ghSu-o',
  authDomain: 'theshop-notification.firebaseapp.com',
  projectId: 'theshop-notification',
  storageBucket: 'theshop-notification.firebasestorage.app',
  messagingSenderId: '532149998873',
  appId: '1:532149998873:web:9a83ed9f79ee24b3e66188',
  measurementId: 'G-MSVWBEQ6RV',
};

// Initialize Firebase
export const app1 = initializeApp(firebaseConfig1, 'app1');
export const app2 = initializeApp(firebaseConfig2, 'app2');

const storage = getStorage(app1);

const messaging = getMessaging(app2);

export { messaging, getToken, onMessage, storage };
