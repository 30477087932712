import Card from 'src/components/common/card';
import { useTranslation } from 'react-i18next';
import { ShopDetailsType } from 'src/ts-types/custom.types';
import { Link } from 'react-router-dom';
import { getSiteDomain, renderShopExpirationDate, renderShopStatus } from 'src/utils/shopUtils';
import { useModalAction } from '../ui/modal/modal.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faIdCard } from '@fortawesome/free-solid-svg-icons';
import Title from '../ui/title';
import { EditLinkIcon } from '../icons/editLink';
import Label from '../ui/label';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ActionButtons from '../common/action-buttons';
import Joyride from 'react-joyride';
import Button from '../ui/button';
import { sendNotification } from 'src/store/shop/shopsAPIActions';
import { useAppDispatch } from 'src/utils/reduxHooks';

type Props = {
  activeShop?: ShopDetailsType;
};

const ShopDetails = ({ activeShop }: Props) => {
  const { t }: any = useTranslation<any>();
  const { openModal } = useModalAction();
  const dispatch = useAppDispatch();

  const steps = [
    {
      target: '.step1',
      content: t('step1'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step2',
      content: t('step2'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step3',
      content: t('step3'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step4',
      content: t('step4'),
      locale: {
        last: 'סיום',
        next: 'סיום', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
  ];
  [
    {
      target: '.tourSelector',
      content: 'This is the coolest feature on this page',
      title: 'You can have a title here!',
      styles: {
        // this styles override the styles in the props
        options: {
          textColor: 'tomato',
        },
      },
      locale: {
        next: <span>GO GO GO</span>,
        back: <span>BACK BACK</span>,
      },
      placement: 'top',
    },
  ];
  const { logoUrl, website, shopId, companyDetails, customerDetails, shopCurrentStatus } =
    activeShop || {};

  const handleDeleteShopModal = () => {
    openModal('DELETE_SHOP', `${activeShop?.shopId}`, `${t('CANCELE_SHOP')}`);
  };

  const handleCloseShopClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    handleDeleteShopModal();
  };

  const handleLinkClick = (e: any, domain: string, shopId?: string) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(getSiteDomain(domain, shopId), '_blank');
  };

  const renderEditIcon = () => {
    return (
      <div className=''>
        <Button
          onClick={() => {
            dispatch(sendNotification({ message: 'test message' }));
          }}
        >
          send Notifiction
        </Button>
        <div className='font-bold w-1/3 float-right'>{t('business-details')}</div>
        <div
          className='float-left cursor-pointer text-accent font-bold'
          style={{ marginRight: '10px' }}
          onClick={(e) => handleCloseShopClick(e)}
        >
          {renderTrashIcon()}
        </div>
      </div>
    );
  };

  const renderTrashIcon = () => {
    return (
      <>
        <span className='text-red-500 '>{t('icon-close-shop')}</span>
        <span className='pr-2 pt-1'>
          <ActionButtons id={activeShop?.shopId || ''} deleteModalView='DELETE_SHOP' />
        </span>
      </>
    );
  };

  // const handleCallback = (data: CallBackProps) => {
  //   const { action, index, type } = data;

  //   if (type === 'step:after' && index === 2) {
  //     if (action === 'next') {
  //       history.push(ROUTES.CATEGORIES);
  //     }
  //   }
  // };

  return (
    <>
      {process.env.TOUR_IS_ACTIVE === 'true' && (
        <Joyride
          steps={steps}
          // callback={handleCallback}
          scrollToFirstStep
          showSkipButton
          styles={{
            options: {
              zIndex: 2000000,
            },
          }}
          continuous
          hideCloseButton
          showProgress
        />
      )}
      <Card title={renderEditIcon()} className='step4'>
        <div className='flex items-center mb-2 py-2 h-[88px] w-[80px]'>
          <img className='pb-4 w-[90px] h-[90px]' src={logoUrl ?? '/bag.png'}></img>
          <div className='flex flex-col md:flex-row'>
            <div>
              <Title className='pr-4'>{` ${companyDetails?.companyName ?? ''}`}</Title>
              <Link
                to=''
                className='pt-2 mr-4 inline-flex items-center text-blue-600 text-accent whitespace-nowrap overflow-hidden text-ellipsis'
                onClick={(e) => handleLinkClick(e, website!, shopId)}
              >
                {website ?? process.env.REACT_APP_DEFAULT_SITE}
                <EditLinkIcon />
              </Link>
            </div>
          </div>
        </div>
        <hr className='h-px bg-gray-100 border-0 dark:bg-gray-200 mb-4'></hr>
        <div className=''>
          <div className='h-30 grid grid-cols-3 sm:grid-cols-2 gap-2'>
            <div>
              <FontAwesomeIcon
                className='cursor-pointer'
                icon={faEnvelope as IconProp}
                size={'1x'}
              />
              <span className='pr-2'>{customerDetails?.emailAddress ?? t('text-email')}</span>
            </div>
            <div>
              <FontAwesomeIcon className='cursor-pointer' icon={faPhone as IconProp} size={'1x'} />
              <span className='pr-2'>{customerDetails?.Phone ?? t('input-label-phone')}</span>
            </div>
            <div>
              <FontAwesomeIcon className='cursor-pointer' icon={faIdCard as IconProp} size={'1x'} />
              <span className='pr-2'>{companyDetails?.companyNumber ?? t('input-company-id')}</span>
            </div>
          </div>
          <Label className='pt-6'>
            {t('shop-status')} {renderShopStatus(shopCurrentStatus)}
          </Label>

          <Label className='pt-5'>
            {t('shop-expiration-date')} {renderShopExpirationDate(activeShop?.shopExpirationDate)}
          </Label>
        </div>
      </Card>
    </>
  );
};

export default ShopDetails;
