import Alert from '../ui/alert';
import Button from '../ui/button';
import Input from '../ui/input';
import PasswordInput from '../ui/password-input';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch } from '../../utils/reduxHooks';
import { googleLogIn, loginByEmail } from 'src/store/session/actions';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/routes';
import { isSuperAdmin, setAuthCredentials } from 'src/utils/authUtils';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { getAllMyShops } from 'src/store/shop/shopsAPIActions';
import jwtDecode from 'jwt-decode';
import { useNotificationSetup } from 'src/HOC/deviceTokenHook';

type FormValues = {
  email: string;
  password: string;
};

const loginFormSchema = yup.object().shape({
  email: yup.string().required('error-email-required'),
  password: yup.string().required('error-password-required'),
});

const defaultValues = {
  email: '',
  password: '',
};

const LoginForm = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null); // Moved userId here for proper control
  const history = useHistory();
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();

  const clientId =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : process.env.REACT_APP_GOOGLE_API_KEY_DEV;

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(loginFormSchema),
    shouldUnregister: true,
    mode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  // Use the hook to manage notifications only if userId exists
  useNotificationSetup(userId);

  function handleCallbackResponse(response: CredentialResponse) {
    const googleToken = response.credential;
    dispatch(googleLogIn(googleToken)).then(async (response: any) => {
      const { accessToken, refreshToken } = response.data;
      if (accessToken) {
        setAuthCredentials(accessToken, refreshToken);
        dispatch(getAllMyShops());
        const decoded = jwtDecode(accessToken) as {
          id: string;
          email: string;
          rule: string;
        };
        setUserId(decoded.id); // Set the userId after successful login
        if (isSuperAdmin()) {
          history.push(ROUTES.SHOPS);
        } else {
          history.push(ROUTES.SHOPS);
        }
      }
    });
  }

  function onSubmit(values: FormValues) {
    dispatch(loginByEmail(values.email, values.password))
      .then(async (response: any) => {
        setIsFetching(true);
        const { accessToken, refreshToken } = response.data;
        setAuthCredentials(accessToken, refreshToken);
        dispatch(getAllMyShops());
        const decoded = jwtDecode(accessToken) as {
          id: string;
          email: string;
          rule: string;
        };
        setUserId(decoded.id); // Set the userId after successful login
        if (isSuperAdmin()) {
          history.push(ROUTES.SHOPS);
        } else {
          history.push(ROUTES.SHOPS);
        }
        setIsFetching(false);
      })
      .catch((error: { response: { data: { systemMessage: any } } }) => {
        console.log(error);
        const { systemMessage } = error?.response?.data || {};
        setErrorMsg(t(systemMessage));
        setIsFetching(false);
      });
  }

  return (
    <>
      {errorMsg && (
        <Alert
          variant='error'
          closeable={true}
          className='mt-5'
          onClose={() => setErrorMsg('')}
          message={errorMsg}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Input
          label={t('input-label-email')}
          {...register('email')}
          type='email'
          variant='outline'
          error={t(errors?.email?.message)}
        />
        <PasswordInput
          label={t('input-label-password')}
          {...register('password')}
          variant='outline'
          forgotPageLink='/forgot-password'
          error={t(errors?.password?.message)}
        />
        <div className='text-center'>
          <Button
            className='mt-6 w-full'
            type='submit'
            loading={isFetching}
            disabled={false}
            theme='rounded'
          >
            {t('button-label-login')}
          </Button>
        </div>
        <div className='relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mt-11 sm:mb-8'>
          <hr className='w-full' />
          <span className='absolute -top-2.5 bg-light px-2 -ms-4 start-2/4'>{t('text-or')}</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <GoogleOAuthProvider clientId={clientId ?? ''}>
            <GoogleLogin
              onSuccess={handleCallbackResponse}
              onError={() => {
                setErrorMsg(t('SHOP_ERROR.GOOGLE_LOGIN_FAIL'));
              }}
              useOneTap
            />
          </GoogleOAuthProvider>
        </div>
        <br />
        <div className='text-sm sm:text-base text-body text-center'>
          {t('text-no-account')}{' '}
          <a
            href={ROUTES.ANONY_CREATE_SHOP}
            className='ms-1 underline text-accent font-semibold transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-700 hover:no-underline focus:no-underline'
          >
            {t('link-register-shop-owner')}
          </a>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
