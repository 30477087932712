import React from 'react';
import { useTranslation } from 'react-i18next';

const StickerCard = ({ titleTransKey, icon, iconBgStyle, count, stepName = '' }: any) => {
  const { t }: any = useTranslation<any>();
  return (
    <div className={stepName}>
      <div className='flex flex-col w-full h-full p-4 rounded bg-light' dir='rtl'>
        <div className='w-full flex justify-between mb-auto'>
          <div className='w-full flex flex-col'>
            <span className='text-base text-heading font-semibold mb-1'>{t(titleTransKey)}</span>
            <span className='text-xl font-semibold text-heading mb-2'>{count}</span>
          </div>

          <div
            className='smx:w-6 smx:h-6 w-12 h-12 rounded-full bg-gray-100 flex flex-shrink-0 items-center justify-center ms-3'
            style={iconBgStyle}
          >
            {icon}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickerCard;
