import Navbar from 'src/components/layouts/navigation/top-navbar';
import { hasAccess } from 'src/utils/authUtils';
import SidebarItem from 'src/components/layouts/navigation/sidebar-item';
import { siteSettings } from 'src/settings/site.settings';
import CreateShop from 'src/pages/createShop/create';
import ShopPage from 'src/pages/dashboard/index';
import TemplatePage from 'src/pages/template/index';
import Categories from 'src/pages/categories/index';
import PaymentPage from 'src/pages/paymentMethod/index';
import ContactUsPage from 'src/pages/contactUsPage/index';
import CreateCategory from 'src/pages/categories/create';
import CreateBrand from 'src/pages/brands/create';
import EditCategory from 'src/pages/categories/edit';
import AddOnsCategories from 'src/pages/addonCategories/index';
import CreateAddOnsCategory from 'src/pages/addonCategories/create';
import EditAddOnsCategory from 'src/pages/addonCategories/edit';
import EditBrand from 'src/pages/brands/edit';
import Products from 'src/pages/products/index';
// import CreateProduct from 'src/pages/products/create';
import EditProduct from 'src/pages/products/edit';
import EditSale from 'src/pages/sales/edit';
import EditShop from 'src/pages/dashboard/edit';
import Orders from 'src/pages/orders/index';
import shippingPage from 'src/pages/shipping';
import salesPage from 'src/pages/sales';
import customerPage from 'src/pages/customers';
import analyticsPage from 'src/pages/analytics';
import SignOut from 'src/pages/logout';
import HomePage from 'src/pages/home/Home';
import EditOrder from 'src/pages/orders/edit';
import { useTranslation } from 'react-i18next';
import MobileNavigation from 'src/components/layouts/navigation/mobile-navigation';
import { useAppSelector } from 'src/utils/reduxHooks';
import { ROUTES } from 'src/utils/routes';
import { useLocation } from 'react-router-dom';
import PrivateRoute from 'src/pages/auth/PrivateRoute';
import PackagePage from 'src/pages/packages';
import CouponPage from 'src/pages/coupons';
import ShopUsers from 'src/pages/employee';
import EditOrCreateCoupon from 'src/pages/coupons/editOrCreateCoupon';
import { getSideBarItemsDisableStatus } from 'src/utils/shopUtils';
import PluginsPage from 'src/pages/plugins';
import CreateOrEditPlugin from 'src/components/plugins/pluginForm';
import Breadcrumb from 'src/components/breadcrumb';
import AlertsPage from 'src/pages/alerts';
import { RootState } from 'src/store';
import ImagesUploader from 'src/pages/imagesUploader';
import AddNewUserForm from 'src/components/shopEmployees/addNewEmployeeForm';
import BrandPage from 'src/pages/brands';
import { MobileNavbar } from '../navigation/mobileNavBar';

const ShopLayout: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const activeShop = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse,
  );
  const location = useLocation();
  const isDisabled = getSideBarItemsDisableStatus(activeShop?.data);
  const showSideBar =
    location.pathname !== ROUTES.ENTRY &&
    location.pathname !== ROUTES.SHOPS &&
    location.pathname !== ROUTES.SHOPS;

  const SidebarItemMap = () => (
    <>
      {siteSettings.sidebarLinks.shop.map(
        ({ href, label, icon, itemPermissions, stepName }, index) => {
          return (
            <>
              {hasAccess(itemPermissions) && (
                <SidebarItem
                  isDisabled={isDisabled}
                  key={`${label}_${index}`}
                  href={href()}
                  label={t(label)}
                  icon={icon}
                  stepName={stepName}
                />
              )}
            </>
          );
        },
      )}
    </>
  );

  return (
    <div className='min-h-screen bg-gray-100 flex flex-col transition-colors duration-150'>
      <Navbar showBurgerMenu={showSideBar} />
      {showSideBar && (
        <MobileNavigation>
          <SidebarItemMap />
        </MobileNavigation>
      )}

      <div className='flex flex-1 pt-16'>
        {showSideBar && (
          <aside className='shadow w-72 xl:w-76 hidden lg:block overflow-y-auto bg-white px-4 fixed start-0 bottom-0 h-full pt-22'>
            <div className='flex flex-col space-y-6 py-3'>
              <SidebarItemMap />
            </div>
          </aside>
        )}
        <main className={`w-full ${showSideBar ? 'lg:ps-72 xl:ps-76' : ''} smx:pb-16`}>
          <div className='lg:pl-5 lg:pr-5 lg:pb-5 sm:p-2 overflow-y-auto h-full smx:pt-3 '>
            <Breadcrumb activeShop={activeShop?.data} />
            <PrivateRoute
              exact
              path={[ROUTES.SHOPS, ROUTES.ENTRY]}
              render={(props: JSX.IntrinsicAttributes) => <HomePage {...props} />}
            />
            {/* <PrivateRoute exact path={`${ROUTES.SHOPS}`} component={AllShopPage} /> */}
            <PrivateRoute exact path={`${ROUTES.CREATE_SHOP}`} component={CreateShop} />
            {/* <PrivateRoute exact path={`${ROUTES.EDIT_SHOP}:id`} component={EditShop} /> */}
            {/* <PrivateRoute exact path={`${ROUTES.TEMPLATE}`} component={TemplatePage} /> */}
            <PrivateRoute exact path={`${ROUTES.CATEGORIES}`} component={Categories} />
            <PrivateRoute exact path={`${ROUTES.CREATE_CATEGORY}`} component={CreateCategory} />
            <PrivateRoute exact path={`${ROUTES.EDIT_CATEGORY}`} component={EditCategory} />
            <PrivateRoute exact path={`${ROUTES.ADDONS_CATEGORIES}`} component={AddOnsCategories} />
            <PrivateRoute
              exact
              path={`${ROUTES.CREATE_CATEGORY_ADDONS}`}
              component={CreateAddOnsCategory}
            />
            <PrivateRoute
              exact
              path={`${ROUTES.EDIT_CATEGORY_ADDONS}`}
              component={EditAddOnsCategory}
            />
            <PrivateRoute exact path={`${ROUTES.BRANDS}`} component={BrandPage} />
            <PrivateRoute exact path={`${ROUTES.CREATE_BRAND}`} component={CreateBrand} />
            <PrivateRoute exact path={`${ROUTES.EDIT_BRAND}`} component={EditBrand} />
            <PrivateRoute exact path={`${ROUTES.PRODUCTS}`} component={Products} />
            {/* <PrivateRoute exact path={`${ROUTES.CREATE_PRODUCT}`} component={CreateProduct} /> */}
            <PrivateRoute exact path={`${ROUTES.EDIT_PRODUCT}`} component={EditProduct} />
            <PrivateRoute exact path={`${ROUTES.SHIPPING}`} component={shippingPage} />
            <PrivateRoute exact path={`${ROUTES.SALES}`} component={salesPage} />
            <PrivateRoute exact path={`${ROUTES.CUSTOMERS}`} component={customerPage} />
            <PrivateRoute exact path={`${ROUTES.ANALYTICS}`} component={analyticsPage} />
            <PrivateRoute exact path={`${ROUTES.EDIT_CREATE_SALE}`} component={EditSale} />
            <PrivateRoute exact path={`${ROUTES.COUPONS}`} component={CouponPage} />
            <PrivateRoute exact path={`${ROUTES.SHOP_USERS}`} component={ShopUsers} />
            <PrivateRoute exact path={`${ROUTES.EDIT_SHOP_EMPLOYEE}`} component={AddNewUserForm} />
            <PrivateRoute exact path={`${ROUTES.OPEN_ALERTS}`} component={AlertsPage} />
            <PrivateRoute
              exact
              path={`${ROUTES.EDIT_CREATE_COUPON}`}
              component={EditOrCreateCoupon}
            />
            <PrivateRoute exact path={`${ROUTES.ORDERS}`} component={Orders} />
            <PrivateRoute exact path={`${ROUTES.ORDERS}:id`} component={EditOrder} />
            <PrivateRoute exact path={`${ROUTES.PLUGINS}`} component={PluginsPage} />
            {/* <PrivateRoute exact path={`${ROUTES.PAYMENT_METHOD}`} component={PaymentPage} /> */}
            <PrivateRoute
              exact
              path={`${ROUTES.EDIT_CREATE_PLUGIN}`}
              component={CreateOrEditPlugin}
            />
            <PrivateRoute exact path={`${ROUTES.PACKAGES}`} component={PackagePage} />
            <PrivateRoute exact path={`${ROUTES.LOGOUT}`} component={SignOut} />
            <PrivateRoute exact path={`${ROUTES.ACTIVE_SHOP}:id`} component={ShopPage} />
            {/* <PrivateRoute exact path={`${ROUTES.CONTACT_US}`} component={ContactUsPage} /> */}
            {/* <PrivateRoute exact path={`${ROUTES.IMAGES_UPLOADER}`} component={ImagesUploader} /> */}
            {/* <PrivateRoute exact path={ROUTES.CHOSE_PLAN} component={ChosePlan} /> */}
          </div>
          <MobileNavbar />
        </main>
      </div>
    </div>
  );
};
export default ShopLayout;
