import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/ui/loader/loader';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { getShopError } from 'src/utils/shopUtils';
import ShopDetails from '../../dashboardComp/shopDetails';
import AnalyticsWidget from 'src/components/common/analyticsWidget';
import { getActiveShopAnalytics } from 'src/store/shop/shopsAPIActions';
import { useEffect } from 'react';
import { RootState } from 'src/store';

const SiteDashboard = () => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();

  const activeShop = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse,
  );
  const analytics = useAppSelector((state: RootState) => state.shop.shopDetails?.analyticsResponse);
  const analyticsNew = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.analyticsResponse,
  );

  useEffect(() => {
    dispatch(getActiveShopAnalytics());
  }, []);

  if (activeShop?.isFetching) return <Loader text={t('text-loading')} />;
  return (
    <>
      {getShopError(activeShop?.data)}
      {!_.isEmpty(activeShop?.data?.shopId) && (
        <div className='pt-2'>
          <AnalyticsWidget analytics={analytics ?? {}} />
          <div className='grid lg:grid-cols-1 gap-4 md:grid-cols-1 sm:grid-cols-1 pt-1'>
            <ShopDetails activeShop={activeShop?.data} />
          </div>
        </div>
      )}
    </>
  );
};

export default SiteDashboard;
