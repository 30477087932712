import {
  ActiveOrderResponse,
  ActiveShopResponse,
  AddOnCategory,
  AddOnsCategories,
  AdminContactUsResponse,
  AllMyAlertsResponse,
  APIResponse,
  Brand,
  Brands,
  Categories,
  Category,
  ContactUsResponse,
  CouponDetails,
  CouponsResponse,
  CreateShopResponse,
  EmployeeDetails,
  MyShopsResponse,
  Order,
  Orders,
  PluginResponse,
  PlugInType,
  Product,
  Products,
  RegisterDomainPayload,
  SaleDetailsResponse,
  SaleSubDetails,
  SaveMode,
  ShippingAdWithRulesResponse,
  ShopAnalyticsResponse,
  ShopEmployeesResponse,
  VariantDetailsList,
} from 'src/ts-types/custom.types';

// Action Definition
export interface ClearAction {
  type: 'CLEAR_STATE';
}
export interface ClearShopStateAction {
  type: 'CLEAR_SHOP_STATE';
}
export interface SetProductsAction {
  type: 'SET_PRODUCTS';
  products: Products;
}
export interface SetCategoriesAction {
  type: 'SET_CATEGORIES';
  categories: Categories;
}
export interface SetAddonsCategoriesAction {
  type: 'SET_ADDONS_CATEGORIES';
  addonsCategories: AddOnsCategories;
}
export interface SetBrandsAction {
  type: 'SET_BRANDS';
  brands: Brands;
}
export interface SetActiveCategoryAction {
  type: 'SET_ACTIVE_CATEGORY';
  category: Category;
}

export interface SetActiveAddonCategoryAction {
  type: 'SET_ACTIVE_ADDON_CATEGORY';
  addon: AddOnCategory;
}
export interface SetActiveBrandAction {
  type: 'SET_ACTIVE_BRAND';
  brand: Brand;
}
export interface SetActionTypeAction {
  type: 'SET_ACTION_TYPE';
  actionType: SaveMode;
}
export interface SetActiveProductAction {
  type: 'SET_ACTIVE_PRODUCT';
  product: Product;
  saveMode: SaveMode;
}
export interface SetFetching {
  type: 'SET_FETCHING';
  isFetching: boolean;
}
export interface SetError {
  type: 'SET_ERROR';
  error: string;
}
export interface CategoryAction {
  type: 'CATEGORY_ACTION';
  category: Category;
}

export interface SetProductVariantAction {
  type: 'SET_PRODUCT_VARIANTS';
  variantDetailsList: VariantDetailsList[];
}

export interface SetProductCurrentVariantAction {
  type: 'SET_PRODUCT_CURRENT_VARIANT';
  currentVariant: VariantDetailsList;
}

export interface DeleteProductAction {
  type: 'DELETE_PRODUCT';
  productId: string;
}
export interface SetOrdersAction {
  type: 'SET_ORDERS';
  orders: Orders;
}
export interface SetUserOrdersAction {
  type: 'SET_USER_ORDERS';
  orders: Orders;
}
export interface SetCouponsAction {
  type: 'SET_COUPONS';
  coupons: CouponsResponse;
}

export interface SetPluginsAction {
  type: 'SET_PLUGINS';
  plugins: PluginResponse;
}
export interface UpdateOrderAction {
  type: 'UPDATE_ORDER';
  order: Order;
}

export interface setFetchOrderByIdResponseAction {
  type: 'FETCH_ORDER_ID';
  order: ActiveOrderResponse;
}

export interface SetShippingAdAndRulesAction {
  type: 'SET_SHIPPING_AD_AND_RULES';
  shippingAdWithRules: ShippingAdWithRulesResponse;
}

export interface DeleteSaleAction {
  type: 'DELETE_SALE';
  saleId: string;
}
//

export interface DeleteShopAction {
  type: 'DELETE_Shop';
  shopId: string;
}

export interface SetActiveSaleAction {
  type: 'SET_ACTIVE_SALE';
  saleDetails: SaleSubDetails;
  saveMode: SaveMode;
}

export interface SetActiveCouponAction {
  type: 'SET_ACTIVE_COUPON';
  couponDetails: CouponDetails;
  saveMode: SaveMode;
}

export interface SetActivePluginAction {
  type: 'SET_ACTIVE_PLUGIN';
  plugin: PlugInType;
  saveMode: SaveMode;
}

export interface SetActiveEmployeeAction {
  type: 'SET_ACTIVE_EMPLOYEE';
  employee: EmployeeDetails;
  saveMode: SaveMode;
}

export interface SetMyShopsResponseAction {
  type: 'SET_MY_SHOPS_RESPONSE';
  response: MyShopsResponse;
}

export interface SetActiveShopResponseAction {
  type: 'SET_ACTIVE_SHOP_DETAILS_RESPONSE';
  response: ActiveShopResponse;
}

export interface SetShopAnalyticsResponseAction {
  type: 'SET_SHOP_ANALYTICS_RESPONSE';
  response: ShopAnalyticsResponse;
}
export interface SetAllSalesResponseAction {
  type: 'SET_ALL_SALES_RESPONSE';
  response: SaleDetailsResponse;
}
export interface SetCreateShopResponseAction {
  type: 'SET_CREATE_SHOP_RESPONSE';
  response: CreateShopResponse;
}
export interface SetShopEmployeesResponseAction {
  type: 'SET_SHOP_EMPLOYEES_RESPONSE';
  response: ShopEmployeesResponse;
}

export interface UpdateShopPrivateDetailsResponseAction {
  type: 'UPDATE_SHOP_PRIVATE_DETAILS_RESPONSE';
  response: APIResponse;
}

export interface UpdateShopPlugInResponseAction {
  type: 'UPDATE_SHOP_PLUGIN_RESPONSE';
  response: APIResponse;
}
export interface UpdateProductResponseAction {
  type: 'UPDATE_PRODUCT_RESPONSE';
  response: APIResponse;
}

export interface UpdateProductsResponseAction {
  type: 'UPDATE_PRODUCTS_RESPONSE';
  response: APIResponse;
}

export interface RegisterDomainAction {
  type: 'REGISTER_DOMAIN';
  payload: RegisterDomainPayload;
}

export interface UpdateContactUsDetailsResponseAction {
  type: 'UPDATE_CONTACT_US_RESPONSE';
  response: ContactUsResponse;
}
export interface SetAllMyAlertsResponseAction {
  type: 'SET_ALL_MY_ALERTS_RESPONSE';
  response: AllMyAlertsResponse;
}
export interface AdminContactUsResponseAction {
  type: 'ADMIN_CONTACT_US_RESPONSE';
  response: AdminContactUsResponse;
}

export interface RefundResponseAction {
  type: 'REFUND_ORDER_RESPONSE';
  response: APIResponse;
}

// export interface UpdateProductAction {
//     type: 'UPDATE_PRODUCT';
//     product: Product;
// }
// export interface CreateProductAction {
//     type: 'CREATE_PRODUCT';
//     product: Product;
// }

// Union Action Types
export type Action =
  | SetFetching
  | SetActiveShopResponseAction
  | SetProductsAction
  | SetError
  | SetCategoriesAction
  | CategoryAction
  | SetActiveCategoryAction
  | SetActiveProductAction
  | SetProductVariantAction
  | SetProductCurrentVariantAction
  | DeleteProductAction
  | DeleteProductAction
  | SetOrdersAction
  | SetUserOrdersAction
  | UpdateOrderAction
  | SetShopAnalyticsResponseAction
  | SetShippingAdAndRulesAction
  | SetAllSalesResponseAction
  | SetActiveSaleAction
  | DeleteSaleAction
  | ClearAction
  | ClearShopStateAction
  | SetMyShopsResponseAction
  | SetCreateShopResponseAction
  | UpdateShopPrivateDetailsResponseAction
  | UpdateShopPlugInResponseAction
  | SetCouponsAction
  | SetActiveCouponAction
  | SetActivePluginAction
  | SetActiveEmployeeAction
  | SetPluginsAction
  | RegisterDomainAction
  | SetAllMyAlertsResponseAction
  | setFetchOrderByIdResponseAction
  | UpdateProductResponseAction
  | UpdateProductsResponseAction
  | UpdateContactUsDetailsResponseAction
  | AdminContactUsResponseAction
  | SetShopEmployeesResponseAction
  | RefundResponseAction
  | SetActiveBrandAction
  | SetBrandsAction
  | SetAddonsCategoriesAction
  | SetActiveAddonCategoryAction;

// Action Creators
export const clear = () => {
  return { type: 'CLEAR_STATE' };
};
export const ShopStateClean = () => {
  return { type: 'CLEAR_SHOP_STATE' };
};

export const setBrands = (brands: Brands): SetBrandsAction => {
  return { type: 'SET_BRANDS', brands };
};
export const setCategories = (categories: Categories): SetCategoriesAction => {
  return { type: 'SET_CATEGORIES', categories };
};
export const setAddOnsCategories = (
  addonsCategories: AddOnsCategories,
): SetAddonsCategoriesAction => {
  return { type: 'SET_ADDONS_CATEGORIES', addonsCategories };
};
export const setProducts = (products: Products): SetProductsAction => {
  return { type: 'SET_PRODUCTS', products };
};

export const isFetching = (isFetching: boolean): SetFetching => {
  return { type: 'SET_FETCHING', isFetching };
};
export const setError = (error: string): SetError => {
  return { type: 'SET_ERROR', error };
};
export const setShopActiveCategory = (category: Category): SetActiveCategoryAction => {
  return { type: 'SET_ACTIVE_CATEGORY', category };
};
export const setShopActiveAddonCategory = (addon: AddOnCategory): SetActiveAddonCategoryAction => {
  return { type: 'SET_ACTIVE_ADDON_CATEGORY', addon };
};
export const setShopActiveBrand = (brand: Brand): SetActiveBrandAction => {
  return { type: 'SET_ACTIVE_BRAND', brand };
};
export const setActionTypeMethod = (actionType: SaveMode): SetActionTypeAction => {
  return { type: 'SET_ACTION_TYPE', actionType };
};

export const setShopActiveProduct = (
  product: Product,
  saveMode: SaveMode,
): SetActiveProductAction => {
  return { type: 'SET_ACTIVE_PRODUCT', product, saveMode };
};
export const updateShopCategory = (category: Category): CategoryAction => {
  return { type: 'CATEGORY_ACTION', category };
};

export const setSProductVariant = (
  variantDetailsList: VariantDetailsList[],
): SetProductVariantAction => {
  return { type: 'SET_PRODUCT_VARIANTS', variantDetailsList };
};

export const setSProductCurrentVariant = (
  currentVariant: VariantDetailsList,
): SetProductCurrentVariantAction => {
  return { type: 'SET_PRODUCT_CURRENT_VARIANT', currentVariant };
};

export const setOrders = (orders: Orders): SetOrdersAction => {
  return { type: 'SET_ORDERS', orders };
};
export const setUserOrders = (orders: Orders): SetUserOrdersAction => {
  return { type: 'SET_USER_ORDERS', orders };
};

export const setCoupons = (coupons: CouponsResponse): SetCouponsAction => {
  return { type: 'SET_COUPONS', coupons };
};

export const setPlugins = (plugins: PluginResponse): SetPluginsAction => {
  return { type: 'SET_PLUGINS', plugins };
};

export const fetchOrderId = (order: ActiveOrderResponse): setFetchOrderByIdResponseAction => {
  return { type: 'FETCH_ORDER_ID', order };
};

export const updateOrderByAdminAction = (order: Order): UpdateOrderAction => {
  return { type: 'UPDATE_ORDER', order };
};

export const setShippingAdWithRules = (
  shippingAdWithRules: ShippingAdWithRulesResponse,
): SetShippingAdAndRulesAction => {
  return { type: 'SET_SHIPPING_AD_AND_RULES', shippingAdWithRules };
};

export const setShopActiveSale = (
  saleDetails: SaleSubDetails,
  saveMode: SaveMode,
): SetActiveSaleAction => {
  return { type: 'SET_ACTIVE_SALE', saleDetails, saveMode };
};
export const setShopActiveCoupon = (
  couponDetails: CouponDetails,
  saveMode: SaveMode,
): SetActiveCouponAction => {
  return { type: 'SET_ACTIVE_COUPON', couponDetails, saveMode };
};

export const setShopActivePlugin = (
  plugin: PlugInType,
  saveMode: SaveMode,
): SetActivePluginAction => {
  return { type: 'SET_ACTIVE_PLUGIN', plugin, saveMode };
};

export const setShopActiveEmployee = (
  employee: EmployeeDetails,
  saveMode: SaveMode,
): SetActiveEmployeeAction => {
  return { type: 'SET_ACTIVE_EMPLOYEE', employee, saveMode };
};

export const setMyShopsResponse = (response: MyShopsResponse): SetMyShopsResponseAction => {
  return { type: 'SET_MY_SHOPS_RESPONSE', response };
};
export const setActiveShopDetailsResponse = (
  response: ActiveShopResponse,
): SetActiveShopResponseAction => {
  return { type: 'SET_ACTIVE_SHOP_DETAILS_RESPONSE', response };
};
export const setShopAnalyticsResponse = (
  response: ShopAnalyticsResponse,
): SetShopAnalyticsResponseAction => {
  return { type: 'SET_SHOP_ANALYTICS_RESPONSE', response };
};

export const setAllSalesResponse = (response: SaleDetailsResponse): SetAllSalesResponseAction => {
  return { type: 'SET_ALL_SALES_RESPONSE', response };
};
export const setCreateShopResponse = (
  response: CreateShopResponse,
): SetCreateShopResponseAction => {
  return { type: 'SET_CREATE_SHOP_RESPONSE', response };
};
export const setShopEmployeesResponse = (
  response: ShopEmployeesResponse,
): SetShopEmployeesResponseAction => {
  return { type: 'SET_SHOP_EMPLOYEES_RESPONSE', response };
};

export const updateShopPrivateDetailsResponse = (
  response: APIResponse,
): UpdateShopPrivateDetailsResponseAction => {
  return { type: 'UPDATE_SHOP_PRIVATE_DETAILS_RESPONSE', response };
};
export const updateShopPlugInResponse = (response: APIResponse): UpdateShopPlugInResponseAction => {
  return { type: 'UPDATE_SHOP_PLUGIN_RESPONSE', response };
};

export const updateProductResponse = (response: APIResponse): UpdateProductResponseAction => {
  return { type: 'UPDATE_PRODUCT_RESPONSE', response };
};

export const updateProductsResponse = (response: APIResponse): UpdateProductsResponseAction => {
  return { type: 'UPDATE_PRODUCTS_RESPONSE', response };
};

export const registerDomain = (payload: RegisterDomainPayload): RegisterDomainAction => {
  return { type: 'REGISTER_DOMAIN', payload };
};

export const setAllMyAlertsResponse = (
  response: AllMyAlertsResponse,
): SetAllMyAlertsResponseAction => {
  return { type: 'SET_ALL_MY_ALERTS_RESPONSE', response };
};

export const updateContactUsDetailsResponse = (
  response: ContactUsResponse,
): UpdateContactUsDetailsResponseAction => {
  return { type: 'UPDATE_CONTACT_US_RESPONSE', response };
};

export const setAdminContactUsResponse = (
  response: AdminContactUsResponse,
): AdminContactUsResponseAction => {
  return { type: 'ADMIN_CONTACT_US_RESPONSE', response };
};

export const setRefundResponse = (response: APIResponse): RefundResponseAction => {
  return { type: 'REFUND_ORDER_RESPONSE', response };
};
