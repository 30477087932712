/* eslint-disable @typescript-eslint/ban-types */
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import BaseService from '../../api/base';
import { API_ENDPOINTS_NEW } from '../../api/endpoints';
import {
  setPlanDetails,
  setError,
  setUpdatePlanDetails,
  setPlanDetailsById,
  setValidateDomainResponse,
} from './actionTypes';
import { UpdatePlanDetails } from 'src/ts-types/custom.types';
import _ from 'lodash';

const service = new BaseService();

export const fetchPlans = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setPlanDetails({ data: [], isFetching: true }));
        service.all(API_ENDPOINTS_NEW.PLANS).then((response) => {
          dispatch(setPlanDetails({ data: response.data, isFetching: false }));
          dispatch(setPlanDetailsById({ data: { price: 0 }, isFetching: false }));
          dispatch(setError(''));
          resolve(response);
        });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setPlanDetails({ data: [], isFetching: false }));
        reject(e);
      }
    });
  };
};

export const UpdatePackage = (
  updatePlanDetails: UpdatePlanDetails,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const url =
          API_ENDPOINTS_NEW.UPDATE_PACKAGE.prefix +
          updatePlanDetails.packageId +
          API_ENDPOINTS_NEW.UPDATE_PACKAGE.suffix;
        dispatch(setUpdatePlanDetails({ isFetching: true }));
        service.update(url, updatePlanDetails).then((response) => {
          dispatch(setUpdatePlanDetails({ isFetching: false }));
          dispatch(setError(''));
          resolve(response);
        });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setUpdatePlanDetails({ isFetching: false }));
        reject(e);
      }
    });
  };
};

export const CreatePackage = (
  updatePlanDetails: UpdatePlanDetails,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        service.create(API_ENDPOINTS_NEW.CREATE_PACKAGE, updatePlanDetails).then((response) => {
          dispatch(setError(''));
          resolve(response);
        });
      } catch (e) {
        dispatch(setError('error'));
        reject(e);
      }
    });
  };
};

export const GetPackageById = (
  packageId: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const url =
          API_ENDPOINTS_NEW.GET_PACKAGE_BY_ID.prefix +
          packageId +
          API_ENDPOINTS_NEW.GET_PACKAGE_BY_ID.suffix;
        dispatch(setPlanDetailsById({ data: { price: 0 }, isFetching: true }));
        service.find(url).then((response) => {
          dispatch(setPlanDetailsById({ data: response?.data, isFetching: false }));
          dispatch(setError(''));
          resolve(response);
        });
      } catch (e) {
        dispatch(setError('error'));
        dispatch(setPlanDetailsById({ data: { price: 0 }, isFetching: false }));
        reject(e);
      }
    });
  };
};

export const ValidateDomain = (
  domainToCheck: string,
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!_.isEmpty(domainToCheck)) {
        try {
          const url = API_ENDPOINTS_NEW.VALIDATE_DOMAIN;
          dispatch(setValidateDomainResponse({ suggestedDomain: [], isFetching: true }));
          service
            .create(url, {
              domainToCheck: `${domainToCheck.trim()}.${process.env.REACT_APP_MAIN_DOMAIN}`,
            })
            .then((response) => {
              const { validDomain, suggestedDomain } = response.data;
              dispatch(
                setValidateDomainResponse({
                  validDomain,
                  suggestedDomain,
                  isFetching: false,
                }),
              );
              dispatch(setError(''));
              resolve(response);
            });
        } catch (e) {
          dispatch(setError('error'));
          dispatch(
            setValidateDomainResponse({
              validDomain: false,
              suggestedDomain: [],
              isFetching: false,
            }),
          );

          reject(e);
        }
      }
    });
  };
};
